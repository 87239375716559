<template>
	<div
	v-if="showCore === true"
	@click="clicked"
	:style="{'--bg-color': color}"
	:class="{'move': movingCore === core.id, 'select': editingCore.id === core.id}"
	coring-map-marker
	>
		{{ core.number }}
	</div>
</template>

<script>
import Vue from "vue";
import coring from "../../utils/coring";
import {mapGetters, mapMutations, mapActions} from "vuex";
import mapboxgl from "mapbox-gl";
import CoringMapPopup from "./Coring.map.popup";

export default Vue.extend({
	name: "CoringMapMarker",
	components: {
		
	},
	props: {
		core: {
			default: false
		},
	},
	data(){
		return {
			clickCount: 0,
		};
	},
	computed: {
		...mapGetters("coring", [
			"editingCore", "map", "movingCore", "campaign", "filter", "filterValue"
		]),

		color(){
			if(this.core.status !== "results_acquired" || !this.filter) return coring.getInfoStatusCore(this.core).color;
			else if(this.filter === "PAH"){
				if(this.sum >= 1000) return "black";
				else if(this.sum >= 500 && this.sum < 1000) return "red";
				else if(this.sum >= 50 && this.sum < 500) return "orange";
				else if(this.sum < 50) return "green";
			}
			else if(this.filter === "asbestos"){
				if(this.asbestos === true) return "red";
				else return "green";
			}
			else if(this.filter === "TH"){
				if(this.sum >= 500) return "orange";
				else if(this.sum < 500) return "green";
			}
		},

		sum(){
			let sum = 0;
			for(const layer of this.core.layersAndInterfaces){
				if(this.filter === "PAH"){
					if(layer.type !== "layer" || layer.PAHResult === null || sum > layer.PAHResult.sum) continue;
					sum = layer.PAHResult.sum;
				}
				else if(this.filter === "TH"){
					if(layer.type !== "layer" || layer.THResult === null || sum > layer.THResult.sum) continue;
					sum = layer.THResult.sum;
				}
			}
			return sum;
		},
		asbestos(){
			for(const layer of this.core.layersAndInterfaces){
				if(layer.asbestosResult?.containsAsbestos === true) return true;
			}
			return false;
		},

		showCore(){
			if(this.core.status !== "results_acquired" || !this.filter) return true;
			else if(this.filter === "PAH"){
				if(this.sum >= 1000 && this.filterValue.PAH.indexOf(1000) === -1) return false;
				else if(this.sum >= 500 && this.sum < 1000 && this.filterValue.PAH.indexOf(500) === -1) return false;
				else if(this.sum >= 50 && this.sum < 500 && this.filterValue.PAH.indexOf(50) === -1) return false;
				else if(this.sum < 50 && this.filterValue.PAH.indexOf(0) === -1) return false;
				else return true;
			}
			else if(this.filter === "asbestos"){
				if(this.asbestos === true && this.filterValue.asbestos.indexOf(true) === -1) return false;
				else if(this.asbestos === false && this.filterValue.asbestos.indexOf(false) === -1) return false;
				else return true;
			}
			else if(this.filter === "TH"){
				if(this.sum >= 500 && this.filterValue.TH.indexOf(500) === -1) return false;
				else if(this.sum < 500 && this.filterValue.TH.indexOf(0) === -1) return false;
				else return true;
			}

		}
	},
	methods: {
		...mapMutations("coring", [
			"START_EDITING_CORE", "START_MOVING_CORE", "STOP_MOVING_CORE"
		]),
		...mapActions("coring", ["updateCore"]),

		clicked(){
			this.clickCount++;
			if(this.clickCount !== 1) return;
			setTimeout(() => {
				if(this.clickCount > 1){
					if(this.movingCore === false && this.campaign.status === "awaiting_validation"){
						this.START_MOVING_CORE(this.core.id);
						setTimeout(this.moveMarker, 100);
					}
				}
				else {
					this.START_EDITING_CORE(this.core);
					if(this.campaign.status === "results_obtained" || this.campaign.status === "status_archived") this.displayPopup();
				}

				this.clickCount = 0;
			}, 200);
		},

		moveMarker(){
			this.map.on("click", this.moveMarkerEventClick);
			this.map.getCanvas().onmouseleave = this.moveMarkerStop;
			this.map.getCanvas().style.cursor = "crosshair";
		},

		moveMarkerEventClick(e){
			this.moveMarkerStop();
			this.updateCore({...this.core, lat: e.lngLat.lat, lng: e.lngLat.lng});
		},

		moveMarkerStop(){
			this.map.off("click", this.moveMarkerEventClick);
			this.map.getCanvas().onmouseleave = () => {};
			this.map.getCanvas().style.cursor = "";
			this.STOP_MOVING_CORE();
		},

		displayPopup(){
			let popup = new mapboxgl.Popup({offset: 25, closeButton: false, closeOnClick: false})
			.setHTML("<div id=\"vue-popup-content\"></div>")
			.setLngLat({lat: this.core.lat, lng: this.core.lng})
			.addTo(this.map);

			new CoringMapPopup({
				propsData: {
					coreData: this.core,
					popup: popup,
				},
				parent: this,
			})
			.$mount("#vue-popup-content");
		},
	},
});
</script>

<style lang="scss">
div[coring-map-marker]{
    color: white;
    background: var(--bg-color);
    aspect-ratio: 1/1;
    height: 20px;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    cursor: pointer;

    &.move{
        animation: move 500ms linear infinite alternate;
    }

    &.select{
        border: solid black 2px;
    }
}

@keyframes move{
    from{
        background: black;
    }

    to{
        background: orange;
    }
}
</style>
