var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.campaignStatus.step > 2 && _vm.core !== false)?_c('div',{staticClass:"tw-bg-[#f1f1f1] tw-absolute tw-transition tw-z-[2] tw-select-none",class:{
	'tw-translate-x-full': _vm.isOpen
}},[(_vm.core.status === 'cancelled')?_c('div',{staticClass:"tw-w-full tw-h-full"},[(!_vm.images[_vm.core.id][7])?_c('WaitingSlot',{staticClass:"tw-h-full tw-w-full tw-bg-[rgba(0,0,0,0.1)]"}):_c('div',{staticClass:"tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-[10px] tw-gap-[10px]"},[_c('h2',[_vm._v("Photo d'annulation")]),_c('img',{staticClass:"tw-max-w-full tw-max-h-[70%]",attrs:{"src":_vm.images[_vm.core.id][7].src}}),(_vm.core.note !== '')?_c('p',[_vm._v(" Raison de l'annulation : "+_vm._s(_vm.core.note)+" ")]):_vm._e()])],1):_c('v-carousel',{staticClass:"tw-h-full tw-w-full",class:{ stop: _vm.editingImage !== false },attrs:{"hide-delimiter-background":"","show-arrows-on-hover":""},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}},_vm._l((_vm.images[_vm.core.id]),function(image,index){return _c('v-carousel-item',{key:index,staticClass:"tw-h-full tw-w-full"},[(image === false)?_c('WaitingSlot',{staticClass:"tw-h-full tw-w-full tw-bg-[rgba(0,0,0,0.1)]"}):(image.src !== false)?_c('div',{staticClass:"tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-[10px] tw-gap-[10px]"},[_c('h2',[_vm._v(_vm._s(_vm.types[image.type]))]),(_vm.editingImage.id !== image.id && _vm.$hasRight('campaigns.useDiapoBtn'))?_c('div',{staticClass:"tw-flex tw-justify-center tw-gap-[10px]"},[_c('ButtonSlot',{attrs:{"_icon":"mdi-crop"},on:{"click":function($event){return _vm.startEditing(image, 'crop')}}}),_c('ButtonSlot',{attrs:{"_icon":"mdi-cached"},on:{"click":function($event){return _vm.startEditing(image, 'rotate')}}}),(image.addedByTechnician === false)?_c('ButtonSlot',{attrs:{"_theme":"light-gray","_popup":{
						title: 'Supprimer l\'image?',
						subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
					}},on:{"click":function($event){return _vm.removeImage(index, image.type)}}},[_vm._v(" supprimer l'image ")]):_c('ButtonSlot',{attrs:{"_theme":"light-gray","_select-file-accept":"image/png, image/jpeg"},on:{"selectFile":function($event){return _vm.addImage($event, index, image.type)}}},[_vm._v(" changer l'image ")])],1):(_vm.editingImage.id === image.id)?_c('div',{staticClass:"tw-flex tw-justify-center tw-gap-[10px]"},[_c('ButtonSlot',{attrs:{"_theme":"none","_icon":"mdi-undo","_tooltip":"Réinitialiser les modification."},on:{"click":function($event){return _vm.resetEdit(_vm.editingImage.editingType)}}}),_c('ButtonSlot',{on:{"click":function($event){return _vm.stopEditing(index)}}},[_vm._v(" enregistrer ")]),_c('ButtonSlot',{attrs:{"_theme":"light-gray"},on:{"click":function($event){_vm.editingImage = false}}},[_vm._v(" annuler ")])],1):_vm._e(),_c('p',{staticClass:"tw-m-0"},[_vm._v(" Origine de l'image : "+_vm._s(image.addedByTechnician ? "Technicien" : "Bureau d'étude")+" ")]),(
					_vm.editingImage.id === image.id &&
						_vm.editingImage.editingType === 'rotate'
				)?_c('div',{staticClass:"tw-flex tw-justify-center tw-gap-[10px] tw-items-center"},[_c('ButtonSlot',{attrs:{"_icon":"mdi-rotate-left"},on:{"click":function($event){return _vm.rotate(-90)}}}),_c('ButtonSlot',{attrs:{"_icon":"mdi-rotate-right"},on:{"click":function($event){return _vm.rotate(90)}}})],1):_vm._e(),(_vm.editingImage.id !== image.id)?_c('div',{staticClass:"tw-shrink tw-grow tw-overflow-hidden tw-max-w-full tw-flex tw-justify-center tw-items-center"},[_c('img',{staticClass:"tw-max-w-full tw-max-h-full image",style:({
						rotate: image.imageConfig.rotationAngle + 'deg',
						'image-orientation': 'none'
					}),attrs:{"src":image.cropped},on:{"click":function($event){return _vm.preview(image.cropped)}}})]):_c('div',{staticClass:"tw-shrink tw-grow tw-overflow-hidden tw-max-w-full tw-flex tw-justify-center tw-items-center"},[(_vm.editingImage.editingType === 'rotate')?_c('img',{staticClass:"tw-max-w-full tw-max-h-full",style:({
						rotate: _vm.editingImage.imageConfig.rotationAngle + 'deg',
						'image-orientation': 'none'
					}),attrs:{"src":image.cropped}}):_c('cropper',{ref:"cropper",refInFor:true,staticClass:"tw-max-w-full tw-max-h-full",attrs:{"defaultPosition":_vm.defaultPosition,"defaultSize":_vm.defaultSize,"src":image.src,"transitions":false,"checkOrientation":false}})],1)]):_c('div',{staticClass:"tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-[10px]"},[_c('h2',[_vm._v(_vm._s(_vm.types[image.type]))]),(_vm.$hasRight('campaigns.useDiapoBtn'))?_c('ButtonSlot',{attrs:{"_select-file-accept":"image/png, image/jpeg"},on:{"selectFile":function($event){return _vm.addImage($event, index, image.type)}}},[_vm._v(" Ajouter une image ")]):_vm._e()],1)],1)}),1),_c('ButtonSlot',{staticClass:"tw-absolute tw-origin-top -tw-rotate-90 tw-top-[50%] tw-left-[100%] -tw-translate-x-[50%]",attrs:{"_icon":_vm.isOpen === true ? 'mdi-chevron-up' : 'mdi-chevron-down',"_icon-size":"30px","_small":"","_theme":"light-gray"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_vm._v(" "+_vm._s(_vm.isOpen === true ? "fermer le diaporama" : "ouvrir le diaporama")+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }