<template>
	<div class="tw-flex tw-flex-col tw-items-center tw-gap-[10px] tw-justify-between tw-h-full">
		<div class="tw-grow tw-overflow-hidden">
			<img
			class="tw-max-h-full"
			:src="picture"
			/>
		</div>
		
		<ButtonSlot
		@click="emit()"
		class="tw-shrink-0"
		>
			Fermer
		</ButtonSlot>
	</div>
</template>

<script>
import Vue from "vue";
import ButtonSlot from "../slots/buttons/Button.slot.vue";

export default Vue.extend({
	components: {ButtonSlot},
	props: {
		picture: {

		}
	}
});
</script>

<style lang="scss">

</style>
