<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		@clickBackButton="$router.push({ name: 'project', params: { id: campaign.workspace.id } })"
		:title="campaign.name"
		>
			<ButtonSlot
			:_to="{
				name: dashboardComponentName,
				params: { id: $route.params.id }
			}"
			_icon="mdi-file-document-outline"
			>
				Tableau de bord
			</ButtonSlot>
		</HeaderSlot>

		<CoringMap
		class="tw-w-full tw-grow"
		>
			<CoringMapMenu
			v-if="cores && campaign.coringType !== 'CS'"
			/>
				
			<MapFiltre
			v-if="cores && campaign.coringType !== 'CS'"
			/>
		</CoringMap>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import auth from "../../service/auth";
import CoringMap from "../../components/coring/Coring.Map.vue";
import MapFiltre from "../../components/map/Map.filtre.vue";
import CoringMapMenu from "../../components/coring/map/Coring.map.menu.vue";

export default {
	name: "StructuralCoringCampaignMap",
	components: {CoringMap, MapFiltre, CoringMapMenu},
	data(){
		return {
			selectedLayers: [],
		};
	},
	computed: {
		...mapGetters("coring", ["campaign", "cores"]),

		dashboardComponentName(){
			this.isGuest = auth.isLoggedGuest();
			return this.campaign.coringType === "CS" 
				? this.isGuest && !auth.isLogged()
					? "structural-coring-campaign-dashboard-guest"
					: "structural-coring-campaign-dashboard"
				: this.isGuest && !auth.isLogged()
					? "coring-campaign-dashboard-guest"
					: "coring-campaign-dashboard";
		}
	},
	methods: {
		...mapActions("coring", [
			"initStore", "purgeStore", "setLayersList"
		]),

	},
	async mounted(){
		await this.initStore({id: this.$route.params.id, coringResult: true});
	},
	destroyed(){
		this.purgeStore();
	}
};
</script>

<style lang="scss">

</style>
