<template>
	<div map>
		<div 
		:id="idDivMap" 
		class="map" 
		ref="map"
		/>

		<div
		v-show="isGeocoderShow"
		:id="idDivGeocoder" 
		class="geocoder" 
		ref="geocoder"
		/>

		<MapNavButton
		v-show="isMapNavButtonShow && map !== false"
		class="navButton"
		:map="map"
		/>

		<div class="slots">
			<slot/>
		</div>
	</div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import auth from "../../service/auth";
import MapNavButton from "./Map.Nav.button.vue";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
	name: "Map",
	components: {
		MapNavButton,
	},
	props: {
		isGeocoderShow: {
			type: Boolean,
			default: true,
		},
		isMapNavButtonShow: {
			type: Boolean,
			default: true,
		}
	},
	data(){
		let id = (Math.random() + 1).toString(36).substring(2);
		return {
			id: id,
			idDivMap: "map-" + id,
			idDivGeocoder: "geocoder-" + id,

			accessTokenMapbox: "pk.eyJ1IjoiYWJhaWxseTUiLCJhIjoiY2t2dGd1ZXFoMHJhODJubHl2OG5ueGo2byJ9.KC7fg_kMFLW-FMsgoU7UPQ",
			style: "mapbox://styles/nextroad-rd/cl0mbzpvc001814qn1leqo1bj",
            
			map: false,
			geocoder: false,

			center: {lng: 2.29, lat: 48.85},
			zoom: 12,
			bounds: {
				maxLng: 0, maxLat: 0, minLng: Infinity, minLat: Infinity
			},
			countries: "fr",
			marker: false,
		};
	},
	watch: {
		style(){
			this.map.setStyle(this.style);
		},
		bounds(){
			this.fitBounds();
		}
	},
	methods: {
		init(){
			this.map = new mapboxgl.Map({
				accessToken: this.accessTokenMapbox,
				container: this.idDivMap,
				style: this.style,
				center: this.center,
				zoom: this.zoom,
				transformRequest(url, resourceType){
					if(resourceType === "Tile" && url.startsWith(process.env.VUE_APP_BASE_URL)){
						return {
							url: url,
							headers: {Authorization: "Bearer " + auth.getJwt()}
						};
					}
				}
			});

			this.map.on("load", this.load);

			this.geocoder = new MapboxGeocoder({
				accessToken: this.accessTokenMapbox,
				mapboxgl: mapboxgl,
				placeholder: "Rechercher",
				flyTo: {duration: 0},
				countries: this.countries,
			});

			this.$refs.geocoder.append(this.geocoder.onAdd(this.map));
			this.geocoder.on("result", this.geocoderResult);
		},

		async load(){
			if(await this.$emit("load", this.map) === false) return;
			this.map.doubleClickZoom.disable();
		},

		async jumpTo(center = this.center, zoom = this.zoom){
			if(await this.$emit("jumpTo", center, zoom) === false) return;
			this.map.jumpTo({
				center: center,
				zoom: zoom,
			});
		},

		async geocoderResult({result}){
			if(await this.$emit("geocoderResult", result) === false) return;
		},

		resize(){
			setTimeout(() => {
				this.map.resize();
			}, 200);
		},

		fitBounds(){
			this.map.fitBounds([
				[this.bounds.maxLng, this.bounds.maxLat], 
				[this.bounds.minLng, this.bounds.minLat] 
			], {padding: 50});
		},
	},
	mounted(){
		this.init();
		window.addEventListener("resize", this.resize);
	},
	unmounted(){
		window.removeEventListener("resize", this.resize);
	},
	created(){
        
	},
	destroyed(){
        
	}
};
</script>

<style lang="scss">
div[map]{
    position: relative;
    overflow: hidden;

    .map{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .geocoder{
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    .navButton{
        position: absolute;
        bottom: 25px;
        right: 10px;
    }

    .slots{
        top: 0;
        left: 0;
        width: 0;
        height: 0;
    }
}
</style>
