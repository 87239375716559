<template>
	<div
	v-if="campaignStatus.step > 2 && core !== false"
	class="tw-bg-[#f1f1f1] tw-absolute tw-transition tw-z-[2] tw-select-none"
	:class="{
		'tw-translate-x-full': isOpen
	}"
	>
		<div
		v-if="core.status === 'cancelled'"
		class="tw-w-full tw-h-full"
		>
			<WaitingSlot
			v-if="!images[core.id][7]"
			class="tw-h-full tw-w-full tw-bg-[rgba(0,0,0,0.1)]"
			/>
			
			<div
			v-else
			class="tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-[10px] tw-gap-[10px]"
			>
				<h2>Photo d'annulation</h2>

				<img
				
				:src="images[core.id][7].src"
				class="tw-max-w-full tw-max-h-[70%]"
				/>
			
				<p v-if="core.note !== ''">
					Raison de l'annulation : {{ core.note }}
				</p>
			</div>
		</div>

		<v-carousel
		v-else
		hide-delimiter-background
		show-arrows-on-hover
		class="tw-h-full tw-w-full"
		:class="{ stop: editingImage !== false }"
		v-model="index"
		>
			<v-carousel-item
			v-for="(image, index) in images[core.id]"
			:key="index"
			class="tw-h-full tw-w-full"
			>
				<WaitingSlot
				v-if="image === false"
				class="tw-h-full tw-w-full tw-bg-[rgba(0,0,0,0.1)]"
				/>

				<div
				class="tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-[10px] tw-gap-[10px]"
				v-else-if="image.src !== false"
				>
					<h2>{{ types[image.type] }}</h2>

					<div
					v-if="editingImage.id !== image.id && $hasRight('campaigns.useDiapoBtn')"
					class="tw-flex tw-justify-center tw-gap-[10px]"
					>
						<ButtonSlot
						@click="startEditing(image, 'crop')"
						_icon="mdi-crop"
						/>

						<ButtonSlot
						@click="startEditing(image, 'rotate')"
						_icon="mdi-cached"
						/>

						<ButtonSlot
						v-if="image.addedByTechnician === false"
						_theme="light-gray"
						@click="removeImage(index, image.type)"
						:_popup="{
							title: 'Supprimer l\'image?',
							subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
						}"
						>
							supprimer l'image
						</ButtonSlot>

						<ButtonSlot
						v-else
						_theme="light-gray"
						@selectFile="addImage($event, index, image.type)"
						_select-file-accept="image/png, image/jpeg"
						>
							changer l'image
						</ButtonSlot>
					</div>

					<div
					v-else-if="editingImage.id === image.id"
					class="tw-flex tw-justify-center tw-gap-[10px]"
					>
						<ButtonSlot
						_theme="none"
						_icon="mdi-undo"
						_tooltip="Réinitialiser les modification."
						@click="resetEdit(editingImage.editingType)"
						/>

						<ButtonSlot @click="stopEditing(index)">
							enregistrer
						</ButtonSlot>

						<ButtonSlot
						_theme="light-gray"
						@click="editingImage = false"
						>
							annuler
						</ButtonSlot>
					</div>

					<p class="tw-m-0">
						Origine de l'image :
						{{ image.addedByTechnician ? "Technicien" : "Bureau d'étude" }}
					</p>

					<div
					v-if="
						editingImage.id === image.id &&
							editingImage.editingType === 'rotate'
					"
					class="tw-flex tw-justify-center tw-gap-[10px] tw-items-center"
					>
						<ButtonSlot
						_icon="mdi-rotate-left"
						@click="rotate(-90)"
						/>

						<ButtonSlot
						_icon="mdi-rotate-right"
						@click="rotate(90)"
						/>
					</div>

					<div
					v-if="editingImage.id !== image.id"
					class="tw-shrink tw-grow tw-overflow-hidden tw-max-w-full tw-flex tw-justify-center tw-items-center"
					>
						<img
						@click="preview(image.cropped)"
						:src="image.cropped"
						class="tw-max-w-full tw-max-h-full image"
						:style="{
							rotate: image.imageConfig.rotationAngle + 'deg',
							'image-orientation': 'none'
						}"
						/>
					</div>

					<div
					v-else
					class="tw-shrink tw-grow tw-overflow-hidden tw-max-w-full tw-flex tw-justify-center tw-items-center"
					>
						<img
						v-if="editingImage.editingType === 'rotate'"
						:src="image.cropped"
						class="tw-max-w-full tw-max-h-full"
						:style="{
							rotate: editingImage.imageConfig.rotationAngle + 'deg',
							'image-orientation': 'none'
						}"
						/>

						<cropper
						v-else
						ref="cropper"
						class="tw-max-w-full tw-max-h-full"
						:defaultPosition="defaultPosition"
						:defaultSize="defaultSize"
						:src="image.src"
						:transitions="false"
						:checkOrientation="false"
						/>
					</div>
				</div>

				<div
				class="tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-[10px]"
				v-else
				>
					<h2>{{ types[image.type] }}</h2>

					<ButtonSlot
					v-if="$hasRight('campaigns.useDiapoBtn')"
					@selectFile="addImage($event, index, image.type)"
					_select-file-accept="image/png, image/jpeg"
					>
						Ajouter une image
					</ButtonSlot>
				</div>
			</v-carousel-item>
		</v-carousel>

		<ButtonSlot
		class="tw-absolute tw-origin-top -tw-rotate-90 tw-top-[50%] tw-left-[100%] -tw-translate-x-[50%]"
		:_icon="isOpen === true ? 'mdi-chevron-up' : 'mdi-chevron-down'"
		_icon-size="30px"
		_small
		_theme="light-gray"
		@click="isOpen = !isOpen"
		>
			{{ isOpen === true ? "fermer le diaporama" : "ouvrir le diaporama" }}
		</ButtonSlot>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import createPopup from "../../utils/popup";
import CoringPopupPicture from "./Coring.popup.picture.vue";

export default {
	component: {
		Cropper
	},
	data(){
		return {
			core: false,
			isOpen: false,
			images: {},
			editingImage: false,
			rotateValue: 90,
			index: 0,
			types: {
				front: "Implantation",
				core: "Carotte",
				hole: "Fond de trou",
				under: "Dessous de la carotte",
				other: "Autre",
				other_2: "Autre 2",
				other_3: "Autre 3",
			}
		};
	},
	computed: {
		...mapGetters("coring", [
			"campaign",
			"editingCore",
			"campaign",
			"campaignStatus"
		]),

		defaultTransforms(){
			return {
				rotate: this.editingImage.imageConfig.rotationAngle || 0
			};
		},

		defaultPosition(){
			return {
				top: this.editingImage.imageConfig.cropY || 0,
				left: this.editingImage.imageConfig.cropX || 0
			};
		},

		defaultSize(){
			return {
				width: this.editingImage.imageConfig.cropWidth || Infinity,
				height: this.editingImage.imageConfig.cropHeight || Infinity
			};
		},

		isCancel(){
			return this.core?.status === "cancelled";
		}
	},
	watch: {
		editingCore(){
			this.editingImage = false;
			if(this.editingCore !== false){
				this.index = 0;
				this.core = {...this.editingCore};

				if(this.core.status === "cancelled") this.types.abandoned = "abandoné";
				else delete this.types.abandoned;

				if(this.images[this.core.id] === undefined) this.images[this.core.id] = [
					false,
					false,
					false,
					false,
					false,
					false,
					false,
				];
				this.getImages();
			}
			else {
				this.isOpen = false;
				this.core = false;
			}
		},
		isOpen(){
			this.editingImage = false;
		}
	},
	methods: {
		...mapActions("coring", ["refreshCore"]),

		async preview(picture){
			let popup = createPopup(CoringPopupPicture, {picture, class: "tw-h-[95%] tw-max-w-[90%]"});
			popup.listen(() => popup.close());
		},

		async getImages(){
			let arr = [];
			for(const [index, type] of Object.keys(this.types).entries()){
				let picture = this.core.corePictures.find(
					value => value.type === type && value.addedByTechnician === false
          		) || 
				this.core.corePictures.find(value => value.type === type);

				if(picture === undefined){
					this.images[this.core.id][index] = {
						src: false,
						type: type
					};
					this.$forceUpdate();
					continue;
				}

				arr.push(this.loadPicture(picture, index, type));
			}
			await Promise.all(arr);
			this.$forceUpdate();
		},

		async loadPicture(picture, index, type){
			let [{imageConfig}, image] = await Promise.all([
				this.$api.corePictures.get(picture.id),
				URL.createObjectURL(
					await this.$api.documents.download(
						picture.path.startsWith(this.campaign.workspace.site.organization.id) ? 
							picture.path : 
							this.campaign.workspace.site.organization.id + "/" + picture.path
					)
				)
			]);

			

			this.images[this.core.id][index] = {
				cropped: await this.resize(image, imageConfig),
				src: image,
				type: type,
				id: picture.id,
				imageConfig: imageConfig,
				addedByTechnician: picture.addedByTechnician
			};
		},

		async addImage({target: {files: [file]}}, index, type){
			let newPicture = await this.$api.corePictures.upload(file, {
				coreId: this.core.id,
				type: type
			});

			let image = URL.createObjectURL(
				await this.$api.documents.download(
					newPicture.path.startsWith(this.campaign.workspace.site.organization.id) ? 
						newPicture.path : 
						this.campaign.workspace.site.organization.id + "/" + newPicture.path
				)
			);

			this.core.corePictures.push(newPicture);
			this.images[this.core.id][index] = {
				cropped: image,
				src: image,
				type: type,
				id: newPicture.id,
				imageConfig: newPicture.imageConfig,
				addedByTechnician: false
			};
			this.refreshCore(this.core);
			this.$forceUpdate();
		},

		async removeImage(index, type){
			for(let index = 0; index < this.core.corePictures.length; index++){
				if(
					this.core.corePictures[index].type === type &&
          this.core.corePictures[index].addedByTechnician === false
				){
					await this.$api.corePictures.remove(this.core.corePictures[index].id);
					this.core.corePictures.splice(index, 1);
					break;
				}
			}

			let techPicture = this.core.corePictures.find(
				value => value.type === type
			);
			if(techPicture !== undefined){
				this.images[this.core.id][index] = false;
				this.$forceUpdate();
				await this.loadPicture(techPicture, index, type);
			}
			else {
				this.images[this.core.id][index] = {
					src: false,
					type: type
				};
			}

			this.refreshCore(this.core);
			this.$forceUpdate();
		},

		async startEditing(image, type){
			this.editingImage = {
				...image,
				editingType: type
			};
		},

		async stopEditing(index){
			if(this.editingImage.editingType === "crop") var result = this.$refs.cropper[0].getResult();
			this.images[this.core.id][index].imageConfig = (
				await this.$api.corePictures.modify(
					this.editingImage.id,
					this.editingImage.editingType === "crop"
						? {
							x: result.coordinates.left,
							y: result.coordinates.top,
							width: result.coordinates.width,
							height: result.coordinates.height
						}
						: {
							rotate: this.editingImage.imageConfig.rotationAngle
						}
				)
			).imageConfig;

			this.images[this.core.id][index].cropped = await this.resize(
				this.images[this.core.id][index].src,
				this.images[this.core.id][index].imageConfig
			);

			this.editingImage = false;
			this.$forceUpdate();
		},

		rotate(value){
			this.editingImage.imageConfig.rotationAngle += value;
			this.$forceUpdate();
		},

		async resize(image, config){
			if(
				config.cropWidth === null ||
				config.cropHeight === null ||
				config.cropX === null ||
				config.cropY === null
			){
				return image;
			}

			let img = new Image();
			img.src = image;
			await img.decode();

			if(
				config.cropWidth === img.width &&
				config.cropHeight === img.height &&
				config.cropX === 0 &&
				config.cropY === 0
			){
				return image;
			}

			let cnv = document.createElement("canvas");
			let ctx = cnv.getContext("2d");

			cnv.width = config.cropWidth || img.width;
			cnv.height = config.cropHeight || img.height;

			ctx.drawImage(
				img,
				config.cropX || 0,
				config.cropY || 0,
				config.cropWidth || img.width,
				config.cropHeight || img.height,
				0,
				0,
				config.cropWidth || img.width,
				config.cropHeight || img.height
			);

			cnv.remove();
			return cnv.toDataURL("image/png", 1);
		},

		async resetEdit(type){
			if(type === "crop"){
				this.$refs.cropper[0].setCoordinates({
					width: Infinity,
					height: Infinity,
					left: 0,
					top: 0
				});
			}
			else {
				this.editingImage.imageConfig.rotationAngle = 0;
				this.$forceUpdate();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
div::v-deep {
  .v-carousel.stop {
    .v-window__next,
    .v-window__prev,
    .v-carousel__controls {
      display: none !important;
    }
  }

  .v-carousel__controls {
    background: rgba(0, 0, 0, 0.5);

    .v-item-group {
      background: transparent;
    }
  }

  .v-carousel__item {
    height: 100% !important;
  }
}
</style>
